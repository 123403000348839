/* eslint-disable react/no-array-index-key */
import React, { lazy, Suspense, Fragment, useContext } from 'react';
import { Routes as RoutesSwitch, Route, useLocation } from 'react-router-dom';

import AffiliationLayout from './layouts/AffiliationLayout';
import AdminLayout from './layouts/AdminLayout';
// import PartnerLayout from './layouts/PartnerLayout';
import NotFound from './pages/NotFound';
import UserContext from './context';
// eslint-disable-next-line import/no-named-as-default
import posthog from 'posthog-js';
import { BlockedError } from './helpers/BlockedError';

// a function to retry loading a chunk to avoid chunk load error for out of date code
const lazyRetry = function(componentImport) {
  return new Promise((resolve, reject) => {
    // check if the window has already been refreshed
    const hasRefreshed = JSON.parse(
      window.sessionStorage.getItem('retry-lazy-refreshed') || 'false'
    );
    // try to import the component
    componentImport()
      .then(component => {
        window.sessionStorage.setItem('retry-lazy-refreshed', 'false'); // success so reset the refresh
        resolve(component);
      })
      .catch(error => {
        if (!hasRefreshed) {
          // not been refreshed yet
          window.sessionStorage.setItem('retry-lazy-refreshed', 'true'); // we are now going to refresh
          return window.location.reload(); // refresh the page
        }
        reject(error); // Default error behaviour as already tried refresh
      });
  });
};

const clientRoutesConfig = [
  {
    layout: AffiliationLayout,
    path: '/',
    component: lazy(() => lazyRetry(() => import('./pages/client/'))),
    authorizedRoles: ['clientAdmin', 'clientMember', 'clientPartner']
  },
  {
    layout: AffiliationLayout,
    path: '/docs/*',
    routes: [
      {
        path: '/',
        component: lazy(() => lazyRetry(() => import('./pages/docs'))),
        authorizedRoles: ['clientAdmin', 'clientMember', 'clientPartner']
      },
      {
        path: '/glossary',
        component: lazy(() => lazyRetry(() => import('./pages/docs/glossary'))),
        authorizedRoles: ['clientAdmin', 'clientMember', 'clientPartner']
      }
    ]
  },
  {
    path: '/brand-safety/*',
    layout: AffiliationLayout,
    allowedProduct: 'brandSafety',
    routes: [
      {
        path: '/',
        allowedProduct: 'brandSafety',
        component: lazy(() =>
          lazyRetry(() => import('./pages/client/brand-safety/home'))
        ),
        authorizedRoles: ['clientAdmin', 'clientMember', 'clientPartner']
      },
      {
        path: '/:domain/:keyword',
        allowedProduct: 'brandSafety',
        component: lazy(() =>
          lazyRetry(() =>
            import('./pages/client/brand-safety/domainKeywordDetails')
          )
        ),
        authorizedRoles: ['clientAdmin', 'clientMember', 'clientPartner']
      },
      {
        path: '/settings',
        allowedProduct: 'brandSafety',
        component: lazy(() =>
          lazyRetry(() => import('./pages/client/brand-safety/settings'))
        ),
        authorizedRoles: ['clientAdmin', 'clientMember']
      }
    ]
  },
  {
    path: '/account/*',
    layout: AffiliationLayout,
    routes: [
      {
        path: '/',
        component: lazy(() =>
          lazyRetry(() => import('./pages/client/account/home'))
        ),
        authorizedRoles: ['clientAdmin', 'clientMember', 'clientPartner']
      },
      {
        path: '/team',
        component: lazy(() =>
          lazyRetry(() => import('./pages/client/account/team'))
        ),
        authorizedRoles: ['clientAdmin', 'clientMember', 'clientPartner']
      }
    ]
  },
  {
    path: '/marketing-analytics/view/:viewId/*',
    layout: AffiliationLayout,
    allowedProduct: 'analytics',
    routes: [
      {
        path: '/',
        allowedProduct: 'analytics',
        component: lazy(() =>
          lazyRetry(() =>
            import('./pages/client/marketing-analytics/acquisition')
          )
        ),
        authorizedRoles: ['clientAdmin', 'clientMember', 'clientPartner']
      },
      {
        path: '/costs',
        allowedProduct: 'analytics',
        component: lazy(() =>
          lazyRetry(() => import('./pages/client/marketing-analytics/costs'))
        ),
        authorizedRoles: ['clientAdmin', 'clientMember', 'clientPartner']
      },
      {
        path: '/technologies',
        allowedProduct: 'analytics',
        component: lazy(() =>
          lazyRetry(() =>
            import('./pages/client/marketing-analytics/technologies')
          )
        ),
        authorizedRoles: ['clientAdmin', 'clientMember', 'clientPartner']
      },
      {
        path: '/channels/:channelId',
        allowedProduct: 'analytics',
        component: lazy(() =>
          lazyRetry(() =>
            import('./pages/client/marketing-analytics/acquisition')
          )
        ),
        authorizedRoles: ['clientAdmin', 'clientMember', 'clientPartner']
      },
      {
        path: '/dashboard',
        allowedProduct: 'analytics',
        component: lazy(() =>
          lazyRetry(() => import('./pages/client/marketing-analytics/custom'))
        ),
        authorizedRoles: ['clientAdmin', 'clientMember', 'clientPartner']
      },
      {
        path: '/attribution/interactions',
        allowedProduct: 'analytics',
        component: lazy(() =>
          lazyRetry(() =>
            import(
              './pages/client/marketing-analytics/attribution/interactions'
            )
          )
        ),
        authorizedRoles: ['clientAdmin', 'clientMember', 'clientPartner']
      },
      {
        path: '/attribution/contribution',
        allowedProduct: 'analytics',
        component: lazy(() =>
          lazyRetry(() =>
            import(
              './pages/client/marketing-analytics/attribution/contribution'
            )
          )
        ),
        authorizedRoles: ['clientAdmin', 'clientMember', 'clientPartner']
      },
      {
        path: '/attribution/attribution',
        allowedProduct: 'analytics',
        component: lazy(() =>
          lazyRetry(() =>
            import('./pages/client/marketing-analytics/attribution/attribution')
          )
        ),
        authorizedRoles: ['clientAdmin', 'clientMember', 'clientPartner']
      },
      {
        path: '/attribution/repetitions',
        allowedProduct: 'analytics',
        component: lazy(() =>
          lazyRetry(() =>
            import('./pages/client/marketing-analytics/attribution/repetition')
          )
        ),
        authorizedRoles: ['clientAdmin', 'clientMember', 'clientPartner']
      },
      {
        path: '/attribution/customerJourney',
        allowedProduct: 'analytics',
        component: lazy(() =>
          lazyRetry(() =>
            import(
              './pages/client/marketing-analytics/attribution/customerJourney'
            )
          )
        ),
        authorizedRoles: ['clientAdmin', 'clientMember', 'clientPartner']
      },

      {
        path: '/custom-attributes/:customAttributeId',
        allowedProduct: 'analytics',
        component: lazy(() =>
          lazyRetry(() =>
            import('./pages/client/marketing-analytics/customAttribute')
          )
        ),
        authorizedRoles: ['clientAdmin', 'clientMember', 'clientPartner']
      },
      {
        path: '/omnicanal',
        allowedProduct: 'analytics',
        component: lazy(() =>
          lazyRetry(() =>
            import('./pages/client/marketing-analytics/omnicanal')
          )
        ),
        authorizedRoles: ['clientAdmin', 'clientMember', 'clientPartner']
      },
      {
        path: '/settings',
        allowedProduct: 'analytics',
        component: lazy(() =>
          lazyRetry(() =>
            import('./pages/client/marketing-analytics/settings/home')
          )
        ),
        authorizedRoles: ['clientAdmin', 'clientMember', 'clientPartner']
      },
      {
        path: '/settings/channels',
        allowedProduct: 'analytics',
        component: lazy(() =>
          lazyRetry(() => import('./templates/channelsGroupingSettings/home'))
        ),
        authorizedRoles: ['clientAdmin', 'clientMember']
      },
      {
        path: '/settings/bo-import',
        allowedProduct: 'analytics',
        component: lazy(() =>
          lazyRetry(() =>
            import(
              './pages/client/marketing-analytics/settings/backOfficeImport'
            )
          )
        ),
        authorizedRoles: ['clientAdmin', 'clientMember']
      },
      {
        path: '/settings/bo-import/new',
        allowedProduct: 'analytics',
        component: lazy(() =>
          lazyRetry(() =>
            import(
              './pages/client/marketing-analytics/settings/backOfficeImport/new'
            )
          )
        ),
        authorizedRoles: ['clientAdmin']
      },
      {
        path: '/settings/bo-import/settings',
        allowedProduct: 'analytics',
        component: lazy(() =>
          lazyRetry(() =>
            import(
              './pages/client/marketing-analytics/settings/backOfficeImport/settings'
            )
          )
        ),
        authorizedRoles: ['clientAdmin']
      },
      {
        path: '/settings/views',
        allowedProduct: 'analytics',
        component: lazy(() =>
          lazyRetry(() =>
            import('./pages/client/marketing-analytics/settings/views')
          )
        ),
        authorizedRoles: ['clientAdmin', 'clientMember']
      },
      {
        path: '/settings/views/add/:viewId?',
        allowedProduct: 'analytics',
        component: lazy(() =>
          lazyRetry(() =>
            import(
              './pages/client/marketing-analytics/settings/views/UpdateView'
            )
          )
        ),
        authorizedRoles: ['clientAdmin', 'clientMember']
      },
      {
        path: '/settings/channels/:channelId',
        allowedProduct: 'analytics',
        component: lazy(() =>
          lazyRetry(() =>
            import('./templates/channelsGroupingSettings/channel')
          )
        ),
        authorizedRoles: ['clientAdmin', 'clientMember']
      },
      {
        path: '/settings/channels/:channelId/subchannels/:subchannelId',
        allowedProduct: 'analytics',
        component: lazy(() =>
          lazyRetry(() =>
            import('./templates/channelsGroupingSettings/subchannel')
          )
        ),
        authorizedRoles: ['clientAdmin', 'clientMember']
      },
      {
        path: '/settings/targets',
        allowedProduct: 'analytics',
        component: lazy(() =>
          lazyRetry(() =>
            import('./pages/client/marketing-analytics/settings/targets')
          )
        ),
        authorizedRoles: ['clientAdmin', 'clientMember']
      },
      {
        path: '/settings/filters',
        allowedProduct: 'analytics',
        component: lazy(() =>
          lazyRetry(() =>
            import('./pages/client/marketing-analytics/settings/filters')
          )
        ),
        authorizedRoles: ['clientAdmin', 'clientMember']
      },
      {
        path: '/settings/attribution',
        allowedProduct: 'analytics',
        component: lazy(() =>
          lazyRetry(() =>
            import('./pages/client/marketing-analytics/settings/attribution')
          )
        ),
        authorizedRoles: ['clientAdmin', 'clientMember']
      },
      {
        path: '/settings/attribution/select',
        allowedProduct: 'analytics',
        component: lazy(() =>
          lazyRetry(() =>
            import(
              './pages/client/marketing-analytics/settings/attribution/selectModel'
            )
          )
        ),
        authorizedRoles: ['clientAdmin', 'clientMember']
      },
      {
        path: '/settings/attribution/customizeModels',
        allowedProduct: 'analytics',
        component: lazy(() =>
          lazyRetry(() =>
            import(
              './pages/client/marketing-analytics/settings/attribution/customizeModels'
            )
          )
        ),
        authorizedRoles: ['clientAdmin', 'clientMember']
      },
      {
        path: '/settings/attribution/customizeModels/:modelId',
        allowedProduct: 'analytics',
        component: lazy(() =>
          lazyRetry(() =>
            import(
              './pages/client/marketing-analytics/settings/attribution/customizeModels/details'
            )
          )
        ),
        authorizedRoles: ['clientAdmin', 'clientMember']
      },
      {
        path: '/settings/attribution/visits',
        allowedProduct: 'analytics',
        component: lazy(() =>
          lazyRetry(() =>
            import(
              './pages/client/marketing-analytics/settings/attribution/visits'
            )
          )
        ),
        authorizedRoles: ['clientAdmin', 'clientMember']
      },
      {
        path: '/settings/custom-attributes',
        allowedProduct: 'analytics',
        component: lazy(() =>
          lazyRetry(() =>
            import(
              './pages/client/marketing-analytics/settings/customAttributes'
            )
          )
        ),
        authorizedRoles: ['clientAdmin', 'clientMember']
      },
      {
        path: '/settings/audience-dimensions',
        allowedProduct: 'analytics',
        component: lazy(() =>
          lazyRetry(() =>
            import(
              './pages/client/marketing-analytics/settings/customAttributes'
            )
          )
        ),
        authorizedRoles: ['clientAdmin', 'clientMember']
      },
      {
        path: '/settings/connectors/',
        allowedProduct: 'analytics',
        component: lazy(() =>
          lazyRetry(() =>
            import('./pages/client/marketing-analytics/settings/connectors')
          )
        ),
        authorizedRoles: ['clientAdmin', 'clientMember', 'clientPartner'],
        myselfVerifications: ['clientConnectorPageAccess']
      },
      {
        path: '/settings/connectors/:connectorType/:providerId',
        allowedProduct: 'analytics',
        component: lazy(() =>
          lazyRetry(() =>
            import(
              './pages/client/marketing-analytics/settings/connectors/adsDetails'
            )
          )
        ),
        authorizedRoles: ['clientAdmin', 'clientMember', 'clientPartner'],
        myselfVerifications: ['clientConnectorPageAccess']
      },
      {
        path: '/settings/manual-costs',
        allowedProduct: 'analytics',
        component: lazy(() =>
          lazyRetry(() =>
            import('./pages/client/marketing-analytics/settings/manualCosts')
          )
        ),
        authorizedRoles: ['clientAdmin', 'clientMember', 'clientPartner']
      },
      {
        path: '/exports',
        allowedProduct: 'analytics',
        component: lazy(() =>
          lazyRetry(() => import('./pages/client/marketing-analytics/exports'))
        ),
        authorizedRoles: ['clientAdmin', 'clientMember', 'clientPartner']
      }
    ]
  },
  {
    path: '/affiliate-marketing/*',
    layout: AffiliationLayout,
    allowedProduct: 'affiliates',
    routes: [
      {
        path: '/exports',
        allowedProduct: 'affiliates',
        component: lazy(() =>
          lazyRetry(() => import('./pages/client/affiliate-marketing/exports'))
        ),
        authorizedRoles: ['clientAdmin', 'clientMember', 'clientPartner']
      },
      {
        path: '/affiliates/:affiliateId',
        allowedProduct: 'affiliates',
        component: lazy(() =>
          lazyRetry(() =>
            import('./pages/client/affiliate-marketing/affiliates/details')
          )
        ),
        authorizedRoles: ['clientAdmin', 'clientMember', 'clientPartner']
      },
      {
        path: '/category/:catId',
        allowedProduct: 'affiliates',
        component: lazy(() =>
          lazyRetry(() => import('./pages/client/affiliate-marketing/analysis'))
        ),
        authorizedRoles: ['clientAdmin', 'clientMember', 'clientPartner']
      },
      {
        path: '/affiliates',
        allowedProduct: 'affiliates',
        component: lazy(() =>
          lazyRetry(() =>
            import('./pages/client/affiliate-marketing/affiliates/list')
          )
        ),
        authorizedRoles: ['clientAdmin', 'clientMember', 'clientPartner']
      },
      {
        path: '/',
        allowedProduct: 'affiliates',
        component: lazy(() =>
          lazyRetry(() => import('./pages/client/affiliate-marketing/analysis'))
        ),
        authorizedRoles: ['clientAdmin', 'clientMember', 'clientPartner']
      },
      {
        path: '/orders',
        allowedProduct: 'affiliates',
        component: lazy(() =>
          lazyRetry(() =>
            import('./pages/client/affiliate-marketing/ordersList')
          )
        ),
        authorizedRoles: ['clientAdmin', 'clientMember', 'clientPartner']
      },
      {
        path: '/orders/:date/:orderId',
        allowedProduct: 'affiliates',
        component: lazy(() =>
          lazyRetry(() =>
            import('./pages/client/affiliate-marketing/orderDetails')
          )
        ),
        authorizedRoles: ['clientAdmin', 'clientMember', 'clientPartner']
      },
      {
        path: '/frauds/:fraudId',
        allowedProduct: 'affiliates',
        component: lazy(() =>
          lazyRetry(() =>
            import('./pages/client/affiliate-marketing/frauds/details')
          )
        ),
        authorizedRoles: ['clientAdmin', 'clientMember', 'clientPartner']
      },
      {
        path: '/simulation',
        allowedProduct: 'affiliates',
        component: lazy(() =>
          lazyRetry(() =>
            import('./pages/client/affiliate-marketing/simulation')
          )
        ),
        authorizedRoles: ['clientAdmin', 'clientMember', 'clientPartner']
      },
      {
        path: '/simulation/:simulationId',
        allowedProduct: 'affiliates',
        component: lazy(() =>
          lazyRetry(() =>
            import('./pages/client/affiliate-marketing/simulation/details')
          )
        ),
        authorizedRoles: ['clientAdmin', 'clientMember', 'clientPartner']
      },
      {
        path: '/simulation/new',
        allowedProduct: 'affiliates',
        component: lazy(() =>
          lazyRetry(() =>
            import('./pages/client/affiliate-marketing/simulation/new')
          )
        ),
        authorizedRoles: ['clientAdmin', 'clientMember']
      },
      {
        path: '/simulation/new/:configId',
        allowedProduct: 'affiliates',
        component: lazy(() =>
          lazyRetry(() =>
            import('./pages/client/affiliate-marketing/simulation/new')
          )
        ),
        authorizedRoles: ['clientAdmin', 'clientMember']
      },
      {
        path: '/settings',
        allowedProduct: 'affiliates',
        component: lazy(() =>
          lazyRetry(() => import('./pages/client/affiliate-marketing/settings'))
        ),
        authorizedRoles: ['clientAdmin', 'clientMember']
      },
      {
        path: '/settings/closing',
        allowedProduct: 'affiliates',
        component: lazy(() =>
          lazyRetry(() =>
            import('./pages/client/affiliate-marketing/settings/Settings')
          )
        ),
        authorizedRoles: ['clientAdmin', 'clientMember']
      },
      {
        path: '/settings/channels',
        allowedProduct: 'affiliates',
        component: lazy(() =>
          lazyRetry(() => import('./templates/channelsGroupingSettings/home'))
        ),
        authorizedRoles: ['clientAdmin', 'clientMember']
      },
      {
        path: '/settings/attributionModel',
        allowedProduct: 'affiliates',
        component: lazy(() =>
          lazyRetry(() =>
            import('./templates/AffiliateAttributionModelSettings')
          )
        ),
        authorizedRoles: ['clientAdmin', 'clientMember']
      },
      {
        path: '/settings/channels/:channelId',
        allowedProduct: 'affiliates',
        component: lazy(() =>
          lazyRetry(() =>
            import('./templates/channelsGroupingSettings/channel')
          )
        ),
        authorizedRoles: ['clientAdmin', 'clientMember']
      },
      {
        path: '/settings/channels/:channelId/subchannels/:subchannelId',
        allowedProduct: 'affiliates',
        component: lazy(() =>
          lazyRetry(() =>
            import('./templates/channelsGroupingSettings/subchannel')
          )
        ),
        authorizedRoles: ['clientAdmin', 'clientMember']
      },
      {
        path: '/settings/weeklyEmail',
        allowedProduct: 'affiliates',
        component: lazy(() =>
          lazyRetry(() =>
            import('./pages/client/affiliate-marketing/settings/WeeklyReport')
          )
        ),
        authorizedRoles: ['clientAdmin', 'clientMember']
      },
      {
        path: '/settings/blockedAffiliates',
        allowedProduct: 'affiliates',
        component: lazy(() =>
          lazyRetry(() =>
            import(
              './pages/client/affiliate-marketing/settings/AffiliatesTransferList'
            )
          )
        ),
        authorizedRoles: ['clientAdmin', 'clientMember']
      },
      {
        path: '/settings/blockedConversionIssues',
        allowedProduct: 'affiliates',
        component: lazy(() =>
          lazyRetry(() =>
            import('./pages/client/affiliate-marketing/settings/BannedFrauds')
          )
        ),
        authorizedRoles: ['clientAdmin', 'clientMember']
      },
      {
        path: '/settings/coupons',
        allowedProduct: 'affiliates',
        component: lazy(() =>
          lazyRetry(() =>
            import('./pages/client/affiliate-marketing/settings/Voucher')
          )
        ),
        authorizedRoles: ['clientAdmin', 'clientMember']
      },
      {
        path: '/settings/attributionModel',
        allowedProduct: 'affiliates',
        component: lazy(() =>
          lazyRetry(() =>
            import('./templates/AffiliateAttributionModelSettings')
          )
        ),
        authorizedRoles: ['clientAdmin', 'clientMember']
      },
      {
        path: '/settings/engagementRate',
        allowedProduct: 'affiliates',
        component: lazy(() =>
          lazyRetry(() => import('./templates/EngagementRate'))
        ),
        authorizedRoles: ['clientAdmin', 'clientMember']
      },
      {
        path: '/settings/deduplicatedChannels',
        allowedProduct: 'affiliates',
        component: lazy(() =>
          lazyRetry(() => import('./templates/DeduplicatedChannelsSettings'))
        ),
        authorizedRoles: ['clientAdmin', 'clientMember']
      },
      {
        path: '/settings/remCats',
        allowedProduct: 'affiliates',
        component: lazy(() =>
          lazyRetry(() => import('./templates/CatRemSettings'))
        ),
        authorizedRoles: ['clientAdmin', 'clientMember']
      },
      {
        path: '/settings/privateProgram',
        allowedProduct: 'affiliates',
        component: lazy(() =>
          lazyRetry(() => import('./templates/PrivateProgramSettings'))
        ),
        authorizedRoles: ['clientAdmin', 'clientMember']
      },
      {
        path: '/closing',
        allowedProduct: 'affiliates',
        component: lazy(() =>
          lazyRetry(() =>
            import('./pages/client/affiliate-marketing/closing/list')
          )
        ),
        authorizedRoles: ['clientAdmin', 'clientMember', 'clientPartner']
      },
      {
        path: '/closing/new',
        allowedProduct: 'affiliates',
        component: lazy(() =>
          lazyRetry(() =>
            import('./pages/client/affiliate-marketing/closing/new')
          )
        ),
        authorizedRoles: ['clientAdmin', 'clientMember']
      },
      {
        path: '/closing/:date',
        allowedProduct: 'affiliates',
        component: lazy(() =>
          lazyRetry(() =>
            import('./pages/client/affiliate-marketing/closing/details')
          )
        ),
        authorizedRoles: ['clientAdmin', 'clientMember', 'clientPartner']
      },
      {
        path: '/extensions',
        allowedProduct: 'affiliates',
        component: lazy(() =>
          lazyRetry(() =>
            import('./pages/client/affiliate-marketing/extensions')
          )
        ),
        authorizedRoles: ['clientAdmin', 'clientMember']
      },
      {
        path: '/recruitment',
        allowedProduct: 'affiliates',
        component: lazy(() =>
          lazyRetry(() =>
            import('./pages/client/affiliate-marketing/recruitment/list')
          )
        ),
        authorizedRoles: ['clientAdmin', 'clientMember', 'clientPartner']
      },
      {
        path: '/recruitment/new',
        allowedProduct: 'affiliates',
        component: lazy(() =>
          lazyRetry(() =>
            import('./pages/client/affiliate-marketing/recruitment/new')
          )
        ),
        authorizedRoles: ['clientAdmin', 'clientMember']
      },
      {
        path: '/recruitment/:date',
        allowedProduct: 'affiliates',
        component: lazy(() =>
          lazyRetry(() =>
            import('./pages/client/affiliate-marketing/recruitment/details')
          )
        ),
        authorizedRoles: ['clientAdmin', 'clientMember', 'clientPartner']
      },
      {
        path: '/rgpd',
        allowedProduct: 'affiliates',
        component: lazy(() =>
          lazyRetry(() =>
            import('./pages/client/affiliate-marketing/rgpd/list')
          )
        ),
        authorizedRoles: ['clientAdmin', 'clientMember', 'clientPartner']
      },
      {
        path: '/rgpd/:projectionClosingDate',
        allowedProduct: 'affiliates',
        component: lazy(() =>
          lazyRetry(() =>
            import('./pages/client/affiliate-marketing/rgpd/details')
          )
        ),
        authorizedRoles: ['clientAdmin', 'clientMember', 'clientPartner']
      }
    ]
  }
];

const adminRoutesConfig = [
  {
    path: '/*',
    layout: AdminLayout,
    routes: [
      {
        path: '/',
        component: lazy(() =>
          lazyRetry(() => import('./pages/admin/dashboard'))
        ),
        authorizedRoles: ['appAdmin']
      },
      {
        path: '/clients',
        component: lazy(() => lazyRetry(() => import('./pages/admin/clients'))),
        authorizedRoles: ['appAdmin']
      },
      {
        path: '/clients/:clientId',
        component: lazy(() => lazyRetry(() => import('./pages/admin/client'))),
        authorizedRoles: ['appAdmin']
      },
      {
        path: '/clients/:clientId/gareports',
        component: lazy(() =>
          lazyRetry(() => import('./pages/admin/client/GoogleAnalyticsReports'))
        ),
        authorizedRoles: ['appAdmin']
      },
      {
        path: '/clients/:clientId/conversionRate',
        component: lazy(() =>
          lazyRetry(() => import('./pages/admin/client/ConversionRate'))
        ),
        authorizedRoles: ['appAdmin']
      },
      {
        path: '/clients/:clientId/dataAuditorTests',
        component: lazy(() =>
          lazyRetry(() => import('./pages/admin/client/dataAuditorTests'))
        ),
        authorizedRoles: ['appAdmin']
      },
      {
        path: '/clients/:clientId/affiliatePlatforms',
        component: lazy(() =>
          lazyRetry(() => import('./pages/admin/client/AffiliatePlatforms'))
        ),
        authorizedRoles: ['appAdmin']
      },
      {
        path: '/clients/:clientId/analyticSolution',
        component: lazy(() =>
          lazyRetry(() => import('./pages/admin/client/AnalyticSolution'))
        ),
        authorizedRoles: ['appAdmin']
      },
      {
        path: '/clients/:clientId/eulerianReports',
        component: lazy(() =>
          lazyRetry(() => import('./pages/admin/client/EulerianReports'))
        ),
        authorizedRoles: ['appAdmin']
      },
      {
        path: '/clients/:clientId/engagementRate',
        component: lazy(() =>
          lazyRetry(() => import('./templates/EngagementRate'))
        ),
        authorizedRoles: ['appAdmin']
      },
      {
        path: '/clients/:clientId/deduplicatedChannels',
        component: lazy(() =>
          lazyRetry(() => import('./templates/DeduplicatedChannelsSettings'))
        ),
        authorizedRoles: ['appAdmin']
      },
      {
        path: '/clients/:clientId/tagSettings',
        component: lazy(() =>
          lazyRetry(() => import('./templates/TagSettings'))
        ),
        authorizedRoles: ['appAdmin']
      },
      {
        path: '/clients/:clientId/deduplicationRules',
        component: lazy(() =>
          lazyRetry(() => import('./templates/DeduplicationRulesSettings'))
        ),
        authorizedRoles: ['appAdmin']
      },
      {
        path: '/clients/:clientId/extensions',
        component: lazy(() =>
          lazyRetry(() => import('./templates/ExtensionsSettings'))
        ),
        authorizedRoles: ['appAdmin']
      },
      {
        path: '/clients/:clientId/brandSafety',
        component: lazy(() =>
          lazyRetry(() => import('./pages/admin/client/BrandSafetySettings'))
        ),
        authorizedRoles: ['appAdmin']
      },
      {
        path: '/clients/:clientId/catRem',
        component: lazy(() =>
          lazyRetry(() => import('./templates/CatRemSettings'))
        ),
        authorizedRoles: ['appAdmin']
      },
      {
        path: '/clients/:clientId/dynamicVariables',
        component: lazy(() =>
          lazyRetry(() => import('./pages/admin/client/DynamicVariables'))
        ),
        authorizedRoles: ['appAdmin']
      },
      {
        path: '/clients/:clientId/rgpd',
        component: lazy(() =>
          lazyRetry(() => import('./pages/admin/client/RgpdSettings'))
        ),
        authorizedRoles: ['appAdmin']
      },
      {
        path: '/clients/:clientId/attribution',
        component: lazy(() =>
          lazyRetry(() => import('./pages/admin/client/AttributionSettings'))
        ),
        authorizedRoles: ['appAdmin']
      },
      {
        path: '/clients/:clientId/affiliateAttributionModel',
        component: lazy(() =>
          lazyRetry(() =>
            import('./templates/AffiliateAttributionModelSettings')
          )
        ),
        authorizedRoles: ['appAdmin']
      },
      {
        path: '/clients/:clientId/analyticsConversion',
        component: lazy(() =>
          lazyRetry(() => import('./pages/admin/client/analyticsConversion'))
        ),
        authorizedRoles: ['appAdmin']
      }
    ]
  }
];

// const partnerRoutesConfig = [
//   {
//     path: '/*',
//     layout: PartnerLayout,
//     routes: [
//       {
//         path: '/',
//         component: lazy(() =>
//           lazyRetry(() => import('./pages/partner/dashboard'))
//         )
//       },
//       {
//         path: '/account',
//         component: lazy(() =>
//           lazyRetry(() => import('./pages/partner/account'))
//         )
//       },
//       {
//         path: '/closedOrders',
//         component: lazy(() => lazyRetry(() => import('./pages/partner/orders')))
//       }
//     ]
//   }
// ];

function usePageViews() {
  const location = useLocation();

  React.useEffect(() => {
    // On location change
    posthog.capture('$pageview');
  }, [location]);
}

function renderRoutes(
  routes,
  allowedProducts = undefined,
  userGroup = undefined,
  isAllowedConnectorPage = undefined
) {
  const RedirectElement = lazy(() =>
    lazyRetry(() => import('./pages/client/'))
  );
  if (routes) {
    return (
      <Suspense>
        <RoutesSwitch>
          {routes.map((route, i) => {
            if (
              (route?.myselfVerifications &&
                !route.myselfVerifications.every(item => {
                  if (item === 'clientConnectorPageAccess') {
                    return !!isAllowedConnectorPage;
                  }

                  return true;
                })) ||
              (route?.authorizedRoles &&
                userGroup &&
                !route.authorizedRoles.includes(userGroup)) ||
              (route?.allowedProduct &&
                allowedProducts &&
                !allowedProducts.includes(route.allowedProduct))
            ) {
              return null;
            }

            const Layout = route.layout || Fragment;
            const Component = route.component;

            return (
              <Route
                key={i}
                path={route.path}
                element={
                  <Layout>
                    {route.routes ? (
                      renderRoutes(
                        route.routes,
                        allowedProducts,
                        userGroup,
                        isAllowedConnectorPage
                      )
                    ) : (
                      <Component />
                    )}
                  </Layout>
                }
              />
            );
          })}
          <Route path="/404" element={<NotFound />} />

          <Route path="*" element={<RedirectElement />} />
        </RoutesSwitch>
      </Suspense>
    );
  }

  return null;
}

function Routes() {
  const { clientSettings, userClientSettings, userGroup, blocked } = useContext(
    UserContext
  );

  if (blocked) {
    throw new BlockedError('User is blocked');
  }
  const allowedProducts = Object.entries(clientSettings.productsAccess)
    .filter(
      ([productId, access]) =>
        access && userClientSettings.productsAccess?.[productId]
    )
    .map(([productId]) => productId);

  const isAllowedConnectorPage =
    clientSettings?.attributionPingsSettings?.isConnectorPageActive;

  usePageViews();
  return renderRoutes(
    clientRoutesConfig,
    allowedProducts,
    userGroup,
    isAllowedConnectorPage
  );
}

function AdminRoutes() {
  return renderRoutes(adminRoutesConfig);
}

// function PartnerRoutes() {
//   usePageViews();
//   return renderRoutes(partnerRoutesConfig);
// }

export {
  Routes,
  AdminRoutes
  // , PartnerRoutes
};
