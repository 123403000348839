import { useEffect, useState } from 'react';
import { useQuery, gql } from '@apollo/client';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';

import { useCustomQuery } from './graphql';

const GET_LOCAL_CLIENT_ID = gql`
  query LocalClientId {
    clientId @client
  }
`;

export const GET_CLIENT_BO_IMPORT = gql`
  query ClientBackOfficeImportListHook($clientId: ID!) {
    clientId @client @export(as: "clientId")
    client(clientId: $clientId) {
      id
      attributionPingsSettings {
        backOfficeImports {
          list {
            id
            status
            author
          }
        }
      }
    }
  }
`;

export const useAnalyticsBackOfficeImport = userEmail => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [ImportInProgressFromAuthor, setImportInProgressFromAuthor] = useState(
    []
  );
  const { data: dataLocalActiveClientId } = useQuery(GET_LOCAL_CLIENT_ID);

  const { data, startPolling, stopPolling, client } = useCustomQuery(
    GET_CLIENT_BO_IMPORT,
    {
      showLoader: false,
      skip: !dataLocalActiveClientId?.clientId || !userEmail,
      errorHandling: {
        signOutOnError: true
      }
    }
  );

  useEffect(() => {
    if (ImportInProgressFromAuthor?.length > 0) {
      for (const importId of ImportInProgressFromAuthor) {
        const importData = data?.client?.attributionPingsSettings?.backOfficeImports?.list?.find(
          ({ id }) => id === importId
        );
        if (importData?.status === 'done') {
          setTimeout(() => {
            enqueueSnackbar(t('ma.backOfficeImport.importDone'), {
              variant: 'success'
            });
            client.reFetchObservableQueries();
          }, 5000);
        }
      }
    }
    if (
      data?.client?.attributionPingsSettings?.backOfficeImports?.list?.length >
      0
    ) {
      setImportInProgressFromAuthor(
        data?.client?.attributionPingsSettings?.backOfficeImports?.list
          ?.filter(
            item =>
              ['waiting', 'inProgress'].includes(item.status) &&
              item.author === userEmail
          )
          ?.map(({ id }) => id) || []
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, userEmail]);

  useEffect(() => {
    if (ImportInProgressFromAuthor.length > 0) {
      startPolling && startPolling(5000);
    } else {
      stopPolling && stopPolling();
    }
    return () => stopPolling();
  }, [ImportInProgressFromAuthor, startPolling, stopPolling]);
};
