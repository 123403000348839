import { colors } from '@mui/material';

export const themeColors = {
  green: '#00CD8D',
  red: '#f92940',
  orange: '#ff9800',
  blue: '#00679E',
  yellow: '#FFAC0A',
  attributionGreen: '#abd7cf',
  attributionPurple: '#CE85CD',
  attributionPink: '#FA9AB1',
  attributionPeach: '#FCBD9C'
};

export const attribGraphColors = [
  '#00679E',
  '#00B2B0',
  '#FF8D00',
  '#E2323C',
  '#9E5BA0',
  '#FFAC0A'
];

export const graphColors = [
  '#00cd8d',
  '#FFAC0A',
  '#00679E',
  '#00B2B0',
  '#FF8D00',
  '#E2323C',
  '#9E5BA0',
  '#4EA100',
  '#95CC53',
  '#00DFDD',
  '#754276',
  '#F6666B'
];

export const theme = {
  direction: 'ltr',
  typography: {
    h1: {
      fontWeight: 500,
      fontSize: 35,
      letterSpacing: '-0.24px'
    },
    h2: {
      fontWeight: 500,
      fontSize: 29,
      letterSpacing: '-0.24px'
    },
    h3: {
      fontWeight: 500,
      fontSize: 24,
      letterSpacing: '-0.06px'
    },
    h4: {
      fontWeight: 500,
      fontSize: 20,
      letterSpacing: '-0.06px'
    },
    h5: {
      fontWeight: 500,
      fontSize: 16,
      letterSpacing: '-0.05px'
    },
    h6: {
      fontWeight: 500,
      fontSize: 14,
      letterSpacing: '-0.05px'
    },
    overline: {
      fontWeight: 500
    }
  },
  components: {
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          background: '#fff',
          padding: '10px',
          border: '1px solid #ccc',
          color: '#263238',
          fontWeight: 400,
          fontSize: 12,
          boxShadow:
            '0 0 0 1px rgba(63,63,68,0.05), 0 1px 2px 0 rgba(63,63,68,0.15)'
        }
      }
    },
    MuiTableCell: {
      styleOverrides: {
        head: {
          fontSize: 12
        },
        sizeSmall: {
          padding: '6px 10px'
        }
      }
    },
    'MuiTableRow-hover': {
      styleOverrides: {
        root: {
          cursor: 'pointer'
        }
      }
    },
    MuiButton: {
      styleOverrides: {
        containedPrimary: {
          color: '#fff'
        }
      }
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          fontSize: 14
        }
      }
    },
    MuiMenu: {
      styleOverrides: {
        paper: {
          maxHeight: 400
        }
      }
    },
    MuiStepIcon: {
      styleOverrides: {
        text: {
          fill: '#fff'
        }
      }
    },
    MuiInputBase: {
      styleOverrides: {
        input: {
          '&::placeholder': {
            opacity: 1,
            color: colors.blueGrey[600]
          }
        }
      }
    }
  },
  palette: {
    mode: 'light',
    action: {
      active: colors.blueGrey[600]
    },
    background: {
      default: colors.common.white,
      dark: '#f4f6f8',
      paper: colors.common.white
    },
    primary: {
      main: themeColors.green
    },
    success: {
      main: themeColors.green
    },
    secondary: {
      main: '#444'
    },
    error: {
      main: '#f44336'
    },
    warning: {
      main: '#ff9800'
    },
    text: {
      primary: colors.blueGrey[900],
      secondary: colors.blueGrey[600]
    }
  },
  shadows: [
    'none',
    '0 0 0 1px rgba(63,63,68,0.05), 0 1px 2px 0 rgba(63,63,68,0.15)',
    '0 0 1px 0 rgba(0,0,0,0.31), 0 2px 2px -2px rgba(0,0,0,0.25)',
    '0 0 1px 0 rgba(0,0,0,0.31), 0 3px 4px -2px rgba(0,0,0,0.25)',
    '0 0 1px 0 rgba(0,0,0,0.31), 0 3px 4px -2px rgba(0,0,0,0.25)',
    '0 0 1px 0 rgba(0,0,0,0.31), 0 4px 6px -2px rgba(0,0,0,0.25)',
    '0 0 1px 0 rgba(0,0,0,0.31), 0 4px 6px -2px rgba(0,0,0,0.25)',
    '0 0 1px 0 rgba(0,0,0,0.31), 0 4px 8px -2px rgba(0,0,0,0.25)',
    '0 0 1px 0 rgba(0,0,0,0.31), 0 5px 8px -2px rgba(0,0,0,0.25)',
    '0 0 1px 0 rgba(0,0,0,0.31), 0 6px 12px -4px rgba(0,0,0,0.25)',
    '0 0 1px 0 rgba(0,0,0,0.31), 0 7px 12px -4px rgba(0,0,0,0.25)',
    '0 0 1px 0 rgba(0,0,0,0.31), 0 6px 16px -4px rgba(0,0,0,0.25)',
    '0 0 1px 0 rgba(0,0,0,0.31), 0 7px 16px -4px rgba(0,0,0,0.25)',
    '0 0 1px 0 rgba(0,0,0,0.31), 0 8px 18px -8px rgba(0,0,0,0.25)',
    '0 0 1px 0 rgba(0,0,0,0.31), 0 9px 18px -8px rgba(0,0,0,0.25)',
    '0 0 1px 0 rgba(0,0,0,0.31), 0 10px 20px -8px rgba(0,0,0,0.25)',
    '0 0 1px 0 rgba(0,0,0,0.31), 0 11px 20px -8px rgba(0,0,0,0.25)',
    '0 0 1px 0 rgba(0,0,0,0.31), 0 12px 22px -8px rgba(0,0,0,0.25)',
    '0 0 1px 0 rgba(0,0,0,0.31), 0 13px 22px -8px rgba(0,0,0,0.25)',
    '0 0 1px 0 rgba(0,0,0,0.31), 0 14px 24px -8px rgba(0,0,0,0.25)',
    '0 0 1px 0 rgba(0,0,0,0.31), 0 16px 28px -8px rgba(0,0,0,0.25)',
    '0 0 1px 0 rgba(0,0,0,0.31), 0 18px 30px -8px rgba(0,0,0,0.25)',
    '0 0 1px 0 rgba(0,0,0,0.31), 0 20px 32px -8px rgba(0,0,0,0.25)',
    '0 0 1px 0 rgba(0,0,0,0.31), 0 22px 34px -8px rgba(0,0,0,0.25)',
    '0 0 1px 0 rgba(0,0,0,0.31), 0 24px 36px -8px rgba(0,0,0,0.25)'
  ]
};
