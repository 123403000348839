/* eslint-disable no-use-before-define */
/* eslint-disable react/prop-types */
import React, { useMemo, useEffect, useContext } from 'react';
import { styled } from '@mui/material/styles';
import { useLocation, Link as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Grid,
  Box,
  Drawer,
  Hidden,
  List,
  ListSubheader,
  Tooltip,
  IconButton,
  Stack
} from '@mui/material';
import {
  PieChart as PieChartIcon,
  Users as UsersIcon,
  ShoppingCart as ShoppingCartIcon,
  UserPlus as UsersPlusIcon,
  Tag as TagIcon
} from 'react-feather';
import PolicyOutlinedIcon from '@mui/icons-material/PolicyOutlined';
import PlayForWorkIcon from '@mui/icons-material/PlayForWork';
import ControlPointDuplicateIcon from '@mui/icons-material/ControlPointDuplicate';
import { gql } from '@apollo/client';
import {
  MdOutlineDashboardCustomize,
  MdOutlineImportantDevices
} from 'react-icons/md';
import { BiCookie, BiStore } from 'react-icons/bi';
import { FiSettings } from 'react-icons/fi';
import { IoPeopleOutline } from 'react-icons/io5';
import { RiTeamLine } from 'react-icons/ri';
import { AiOutlineEuro, AiOutlineDollar } from 'react-icons/ai';
import { CgAttribution } from 'react-icons/cg';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import { useTranslation } from 'react-i18next';
import GetAppIcon from '@mui/icons-material/GetApp';
import { useAtom } from 'jotai';

import i18next from '../../../i18n';
import { useAffiliateCategory } from '../../../hooks/AffiliateCategory';
import UserContext, { exportShowNotificationMainMenu } from '../../../context';
import { FRAUDS } from '../../../constants/frauds';
import { TY_PRODUCTS } from '../../../constants/products';
import Logo from '../../../atoms/Logo';
import FeedbackForm from '../../../organisms/FeedbackForm';
import NavItem from './NavItem';
import ClientSelect from './ClientSelect';
import { useCustomQuery } from '../../../hooks/graphql';

import { rbac } from '../../../rbac';
import { useAnalyticsHelpers } from '../../../hooks/analyticsHelpers';
import ViewSelect from './ViewSelect';

const PREFIX = 'NavBar';

const classes = {
  desktopDrawer: `${PREFIX}-desktopDrawer`,
  forbiddenLink: `${PREFIX}-forbiddenLink`
};

const Root = styled('div')(({ theme }) => ({
  [`& .${classes.desktopDrawer}`]: {
    width: 320,
    height: '100%'
  },

  [`& .${classes.forbiddenLink}`]: {
    cursor: 'not-allowed'
  }
}));

const FirstMenuStyled = styled(Box)(({ theme }) => ({
  background: theme.palette.background.dark,
  borderRight: '1px solid rgba(0, 0, 0, 0.12)'
}));

const GET_CLIENT_CATEGORIES_DISTRIBUTION = gql`
  query NavBarClientCategories(
    $from: String!
    $to: String!
    $selectedAffiliatePlatformIds: [String!]
    $localDynamicVariables: [argDynamicVariablesInput]!
    $localFilterSentToPlatform: [String]!
    $localFilterClosingStatuses: [String]
    $clientId: ID!
  ) {
    datePickerFrom @client @export(as: "from")
    datePickerTo @client @export(as: "to")
    selectedAffiliatePlatformIds
      @client
      @export(as: "selectedAffiliatePlatformIds")
    dynamicVariables @client @export(as: "localDynamicVariables") {
      name
      values
    }
    filterClosingStatuses @client @export(as: "localFilterClosingStatuses")
    filterSentToPlatform @client @export(as: "localFilterSentToPlatform")
    client(clientId: $clientId) {
      id
      affiliateCategoriesDistributionV2(
        from: $from
        to: $to
        affiliatePlatformIds: $selectedAffiliatePlatformIds
        dynamicVariables: $localDynamicVariables
        sentToPlatform: $localFilterSentToPlatform
        closingStatuses: $localFilterClosingStatuses
      ) {
        id
        count
      }
    }
  }
`;

const navConfigAnalytics = (
  customAttributes,
  showExportNotif,
  userGroup,
  currency,
  hasAccessToOmnicanal,
  selectedView,
  availableConnectors,
  accessManualCosts
) => {
  const toReturn = [
    {
      subheader: 'app',
      showSubHeader: false,
      items: [
        {
          title: i18next.t('ma.mainMenu.realTime'),
          icon: MdOutlineDashboardCustomize,
          href: `/marketing-analytics/view/${selectedView}/dashboard`
        },
        {
          title: i18next.t('ma.mainMenu.acquisition'),
          icon: PieChartIcon,
          href: `/marketing-analytics/view/${selectedView}`,
          activeIncludes: `marketing-analytics/view/${selectedView}/channels`
        }
      ]
    }
  ];

  if (hasAccessToOmnicanal) {
    toReturn[0].items.push({
      title: i18next.t('ma.omnicanal.title'),
      icon: BiStore,
      href: `/marketing-analytics/view/${selectedView}/omnicanal`,
      activeIncludes: `/marketing-analytics/view/${selectedView}/omnicanal`
    });
  }

  toReturn[0].items.push({
    title: 'Mix marketing',
    icon: CgAttribution,
    items: [
      {
        title: 'Attribution',
        href: `/marketing-analytics/view/${selectedView}/attribution/attribution`,
        activeIncludes: `marketing-analytics/view/${selectedView}/attribution/attribution`
      },

      {
        title: 'Contribution',
        href: `/marketing-analytics/view/${selectedView}/attribution/contribution`,
        activeIncludes: `marketing-analytics/view/${selectedView}/attribution/contribution`
      },
      {
        title: 'Interactions',
        href: `/marketing-analytics/view/${selectedView}/attribution/interactions`
      },
      {
        title: i18next.t('ma.attrib.repetition.title'),
        href: `/marketing-analytics/view/${selectedView}/attribution/repetitions`
      },
      {
        title: i18next.t('ma.attrib.customerJourney.title'),
        href: `/marketing-analytics/view/${selectedView}/attribution/customerJourney`
      }
    ]
  });

  const conversionDimensions = customAttributes?.filter(
    ({ dimension }) => dimension === 'conversion'
  );
  if (conversionDimensions?.length > 0) {
    toReturn[0].items.push({
      title: i18next.t('ma.commun.conversions'),
      icon: TagIcon,
      items: conversionDimensions?.map(ca => ({
        title: ca.name,
        href: `/marketing-analytics/view/${selectedView}/custom-attributes/${ca.internalAttributKey}`
      }))
    });
  }

  const audienceDimensions = customAttributes?.filter(
    ({ dimension }) => dimension === 'audience'
  );
  if (audienceDimensions?.length > 0) {
    toReturn[0].items.push({
      title: i18next.t('ma.commun.audiences'),
      icon: IoPeopleOutline,
      items: audienceDimensions?.map(ca => ({
        title: ca.name,
        href: `/marketing-analytics/view/${selectedView}/custom-attributes/${ca.internalAttributKey}`
      }))
    });
  }

  toReturn[0].items.push({
    title: i18next.t('commun.technologies'),
    icon: MdOutlineImportantDevices,
    href: `/marketing-analytics/view/${selectedView}/technologies`,
    activeIncludes: `marketing-analytics/view/${selectedView}/technologies`
  });

  if (accessManualCosts) {
    toReturn[0].items.push({
      title: i18next.t('ma.mainMenu.costs'),
      icon: currency === 'EUR' ? AiOutlineEuro : AiOutlineDollar,
      href: `/marketing-analytics/view/${selectedView}/costs`,
      activeIncludes: `marketing-analytics/view/${selectedView}/costs`
    });
  }

  toReturn[0].items.push({
    title: i18next.t('commun.exports'),
    icon: GetAppIcon,
    href: `/marketing-analytics/view/${selectedView}/exports`,
    showBadge: showExportNotif
  });

  if (
    rbac['accessMarketingAnalyticsSettings'].includes(userGroup) ||
    rbac['accessReadOnlyMarketingAnalyticsSettings'].includes(userGroup) ||
    (rbac['accessMarketingAnalyticsSettingsIfActiveConnector'].includes(
      userGroup
    ) &&
      availableConnectors.length > 0)
  ) {
    toReturn[0].items.push({
      title: i18next.t('ma.mainMenu.parameters'),
      icon: FiSettings,
      href: `/marketing-analytics/view/${selectedView}/settings`,
      activeIncludes: `/marketing-analytics/view/${selectedView}/settings`
    });
  }

  return toReturn;
};

const navConfigBrandSafety = userGroup => {
  const toReturn = [
    {
      subheader: 'app',
      showSubHeader: false,
      items: [
        {
          title: 'Dashboard',
          icon: PieChartIcon,
          href: '/brand-safety'
        }
      ]
    }
  ];

  if (
    rbac['accessBrandSafetySettings'].includes(userGroup) ||
    rbac['accessReadOnlyBrandSafetySettings'].includes(userGroup)
  ) {
    toReturn[0].items.push({
      title: i18next.t('commun.parameters'),
      icon: FiSettings,
      href: '/brand-safety/settings',
      activeIncludes: '/brand-safety/settings'
    });
  }

  return toReturn;
};

const navConfigDocs = (tagId, username) => [
  {
    subheader: 'app',
    showSubHeader: false,
    items: [
      {
        title: i18next.t('support.title'),
        icon: HelpOutlineIcon,
        href: '/docs',
        activeIncludes: 'category',
        items: [
          {
            title: i18next.t('support.techDoc.title'),
            onClick: () => {
              window
                .open(
                  `https://thank-you.io/docs/marketing-analytics-documentation-technique${
                    tagId ? `?tagId=${tagId}` : ''
                  }`,
                  '_blank'
                )
                .focus();
            }
          },
          {
            title: i18next.t('support.glossary.title'),
            href: `/docs/glossary`
          },
          {
            title: i18next.t('feedbackForm.title'),
            textComponent: () => (
              <FeedbackForm view="text" username={username} />
            )
          }
        ]
      }
    ]
  }
];

const navConfigAccount = () => [
  {
    subheader: 'app',
    showSubHeader: false,
    items: [
      {
        title: i18next.t('account.mainMenu.general'),
        icon: PieChartIcon,
        href: '/account'
      },
      {
        title: i18next.t('account.mainMenu.team'),
        icon: RiTeamLine,
        href: '/account/team'
      }
    ]
  }
];

const navConfigAffiliate = (
  hasStoreSet,
  categories,
  clientCategoriesDistribution,
  tagIsSet,
  showGdprPage,
  userGroup,
  showExportNotif
) => {
  const result = [
    {
      subheader: 'app',
      showSubHeader: false,
      items: [
        {
          title: i18next.t('commun.analyzis'),
          icon: PieChartIcon,
          href: '/affiliate-marketing',
          activeIncludes: 'category',
          items: [
            ...categories
              .filter(cat =>
                clientCategoriesDistribution.some(
                  ({ id, count }) => cat.id === id && count > 0
                )
              )
              .map(cat => ({
                title: cat.name,
                href: `/affiliate-marketing/category/${cat.id}`
              })),
            {
              title: i18next.t('commun.noCat'),
              href: `/affiliate-marketing/category/none`
            }
          ]
        },
        {
          title: i18next.t('am.mainMenu.conversionsIssues'),
          activeIncludes: 'frauds',
          icon: PolicyOutlinedIcon,
          items: Object.entries(FRAUDS)
            .filter(
              ([key, value]) =>
                value.detailsPage &&
                (key !== 'inStore' || (key === 'inStore' && hasStoreSet)) &&
                (key !== 'transactionalCookieDropping' ||
                  (key === 'transactionalCookieDropping' && tagIsSet)) &&
                (key !== 'cookieDropping' ||
                  (key === 'cookieDropping' && !tagIsSet))
            )
            .map(([key, value]) => ({
              title: i18next.t(value.name),
              href: `/affiliate-marketing/frauds/${key}`
            }))
        },
        {
          title: i18next.t('am.mainMenu.myAffiliates'),
          icon: UsersIcon,
          href: '/affiliate-marketing/affiliates',
          activeIncludes: 'affiliate-marketing/affiliates'
        },
        {
          title: i18next.t('am.mainMenu.myConversions'),
          icon: ShoppingCartIcon,
          href: '/affiliate-marketing/orders',
          activeIncludes: '/affiliate-marketing/orders'
        },
        {
          title: i18next.t('am.mainMenu.simulations'),
          icon: ControlPointDuplicateIcon,
          href: '/affiliate-marketing/simulation',
          activeIncludes: '/affiliate-marketing/simulation'
        },
        {
          title: i18next.t('am.mainMenu.closing'),
          icon: PlayForWorkIcon,
          href: '/affiliate-marketing/closing',
          activeIncludes: 'affiliate-marketing/closing'
        },
        {
          title: i18next.t('commun.exports'),
          icon: GetAppIcon,
          href: '/affiliate-marketing/exports',
          showBadge: showExportNotif
        },
        {
          title: i18next.t('am.mainMenu.recruitment'),
          icon: UsersPlusIcon,
          href: '/affiliate-marketing/recruitment',
          activeIncludes: 'affiliate-marketing/recruitment'
        }
      ]
    }
  ];

  if (showGdprPage) {
    result[0].items.splice(6, 0, {
      title: 'Bonus ePrivacy',
      icon: BiCookie,
      href: '/affiliate-marketing/rgpd',
      activeIncludes: '/affiliate-marketing/rgpd'
    });
  }

  if (
    rbac['accessAffiliateSettings'].includes(userGroup) ||
    rbac['accessReadOnlyAffiliateSettings'].includes(userGroup)
  ) {
    result[0].items.push({
      title: i18next.t('commun.parameters'),
      icon: FiSettings,
      href: '/affiliate-marketing/settings'
    });
  }
  return result;
};

function renderNavItems({ items, ...rest }) {
  return (
    <List disablePadding>
      {items.reduce(
        (acc, item) => reduceChildRoutes({ acc, item, ...rest }),
        []
      )}
    </List>
  );
}

function reduceChildRoutes({ acc, pathname, item, depth = 0 }) {
  if (!item) {
    return acc;
  }

  const key = item.title + depth;

  if (item.items) {
    acc.push(
      <NavItem
        depth={depth}
        icon={item.icon}
        showBadge={item?.showBadge === true}
        key={key}
        info={item.info}
        open
        title={item.title}
        href={item?.href}
        activeIncludes={item.activeIncludes || null}
      >
        {renderNavItems({
          depth: depth + 1,
          pathname,
          items: item.items
        })}
      </NavItem>
    );
  } else {
    acc.push(
      <NavItem
        depth={depth}
        href={item.href}
        icon={item.icon}
        showBadge={item?.showBadge === true}
        key={key}
        info={item.info}
        title={item.title}
        onClick={item?.onClick}
        TextComponent={item?.textComponent}
        activeIncludes={item.activeIncludes || null}
      />
    );
  }

  return acc;
}

function NavBar({ openMobile, onMobileClose }) {
  const [showExportNotif] = useAtom(exportShowNotificationMainMenu);
  const { t } = useTranslation();
  const location = useLocation();
  const {
    clientSettings,
    userClientSettings,
    username,
    userGroup,
    selectedView,
    availableConnectors,
    accessManualCosts
  } = useContext(UserContext);
  const { hasAccessToOmnicanal } = useAnalyticsHelpers();

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
    // eslint-disable-next-line
  }, [location.pathname]);

  const activeProduct = useMemo(() => {
    let active = null;
    if (location.pathname.includes('marketing-analytics')) {
      active = 'analytics';
    } else if (location.pathname.includes('affiliate-marketing')) {
      active = 'affiliates';
    } else if (location.pathname.includes('brand-safety')) {
      active = 'brandSafety';
    } else if (location.pathname.includes('account')) {
      active = 'account';
    } else if (location.pathname.includes('docs')) {
      active = 'docs';
    }

    if (active !== 'account') {
      localStorage.setItem('ty_active_product', active);
    }
    return active;
  }, [location]);

  const { getAllCategories } = useAffiliateCategory(
    activeProduct !== 'affiliates'
  );

  const { data } = useCustomQuery(GET_CLIENT_CATEGORIES_DISTRIBUTION, {
    variables: {
      clientId: clientSettings.id
    },
    showLoader: false,
    skip: activeProduct !== 'affiliates'
  });

  const secondaryMenuItems = () => {
    if (!activeProduct) {
      return null;
    }

    if (activeProduct === 'affiliates') {
      if (!data) {
        return null;
      }

      return navConfigAffiliate(
        clientSettings.hasStoreFraudSet,
        getAllCategories,
        data.client.affiliateCategoriesDistributionV2,
        clientSettings.tag.isSet,
        clientSettings.gdprSettings.showPage,
        userGroup,
        showExportNotif
      );
    }
    if (activeProduct === 'analytics') {
      return navConfigAnalytics(
        clientSettings?.attributionPingsSettings?.customAttributes,
        showExportNotif,
        userGroup,
        clientSettings?.currency,
        hasAccessToOmnicanal,
        selectedView,
        availableConnectors,
        accessManualCosts
      );
    }
    if (activeProduct === 'brandSafety') {
      return navConfigBrandSafety(userGroup);
    }
    if (activeProduct === 'docs') {
      return navConfigDocs(clientSettings?.tag?.tagId, username);
    }

    if (activeProduct === 'account') {
      return navConfigAccount();
    }
  };

  if (!activeProduct) {
    return null;
  }

  const content = (
    <Box height="100%" display="flex" flexDirection="row">
      <FirstMenuStyled height="100%" display="flex" flexDirection="column">
        <div style={{ overflowY: 'auto', height: '100%' }}>
          <Grid
            container
            style={{ height: '100%' }}
            direction="column"
            justifyContent="space-between"
          >
            <Grid item>
              <Box mt={3.5} mx={1} display="flex" justifyContent="center">
                <RouterLink to="/">
                  <Logo size="small" />
                </RouterLink>
              </Box>

              <Box
                p={1}
                mt={2}
                display="flex"
                flexDirection="column"
                alignItems="center"
              >
                {Object.entries(TY_PRODUCTS(selectedView)).map(
                  ([productId, productData]) => {
                    const IconComponent = productData.icon;

                    const hasAccess =
                      userClientSettings?.productsAccess[productId] &&
                      clientSettings.productsAccess[productId];

                    return (
                      <React.Fragment key={productId}>
                        {hasAccess ? (
                          <RouterLink to={productData.path}>
                            <Tooltip
                              arrow
                              title={
                                <Stack
                                  direction="row"
                                  alignItems="center"
                                  justifyContent="center"
                                  spacing={1}
                                >
                                  <Box>
                                    <Logo size="xsmall" />
                                  </Box>
                                  <Box>{productData.name}</Box>
                                </Stack>
                              }
                              placement="right"
                            >
                              <IconButton
                                color={
                                  activeProduct === productId
                                    ? 'primary'
                                    : 'default'
                                }
                                style={{ width: '46px' }}
                              >
                                <IconComponent />
                              </IconButton>
                            </Tooltip>
                          </RouterLink>
                        ) : (
                          <a
                            href={productData.marketingWebsiteUrl}
                            target="_blank"
                            rel="noreferrer"
                            className={classes.forbiddenLink}
                          >
                            <Tooltip
                              arrow
                              title={`${productData.name}, ${t(
                                'titles.solutionNoAccess'
                              )}`}
                              placement="right"
                            >
                              <IconButton
                                className={classes.forbiddenLink}
                                color="default"
                                style={{ width: '46px' }}
                              >
                                <IconComponent />
                              </IconButton>
                            </Tooltip>
                          </a>
                        )}
                      </React.Fragment>
                    );
                  }
                )}
              </Box>
            </Grid>
            <Grid item>
              <Box mb={5} mx={1}>
                <Grid container spacing={0} direction="column">
                  <Grid item>
                    <RouterLink to="/docs">
                      <Tooltip arrow title="Support" placement="right">
                        <IconButton
                          color={
                            activeProduct === 'docs' ? 'primary' : 'default'
                          }
                          size="large"
                        >
                          <HelpOutlineIcon />
                        </IconButton>
                      </Tooltip>
                    </RouterLink>
                  </Grid>
                  <Grid item>
                    <RouterLink to="/account">
                      <Tooltip arrow title={username} placement="right">
                        <IconButton
                          color={
                            activeProduct === 'account' ? 'primary' : 'default'
                          }
                          size="large"
                        >
                          <AccountCircleOutlinedIcon />
                        </IconButton>
                      </Tooltip>
                    </RouterLink>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          </Grid>
        </div>
      </FirstMenuStyled>
      {activeProduct && (
        <Box height="100%" width="100%" display="flex" flexDirection="column">
          <div style={{ overflowY: 'auto' }}>
            <Box py={4} px={2}>
              <Grid container direction="column" spacing={2}>
                <Grid item>
                  <ClientSelect />
                  <ViewSelect />
                </Grid>
                <Grid item>
                  {secondaryMenuItems()?.map(config => (
                    <List
                      key={config.subheader}
                      subheader={
                        config.showSubHeader && (
                          <ListSubheader disableGutters disableSticky>
                            {config.subheader}
                          </ListSubheader>
                        )
                      }
                    >
                      {renderNavItems({
                        items: config.items,
                        pathname: location.pathname
                      })}
                    </List>
                  ))}
                </Grid>
              </Grid>
            </Box>
          </div>
        </Box>
      )}
    </Box>
  );

  return (
    <Root>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden lgDown>
        <Drawer
          anchor="left"
          classes={{ paper: classes.desktopDrawer }}
          open
          variant="persistent"
        >
          {content}
        </Drawer>
      </Hidden>
    </Root>
  );
}

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool
};

export default React.memo(NavBar);
