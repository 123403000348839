import React, { useEffect } from 'react';
import { styled } from '@mui/material/styles';
import {
  Typography,
  Button,
  Grid,
  useTheme,
  useMediaQuery,
  Box
} from '@mui/material';
import PropTypes from 'prop-types';

import Page from '../layouts/Page';
import { t } from 'i18next';

const StyledPage = styled(Page)(({ theme }) => ({
  padding: theme.spacing(3),
  paddingTop: '10vh',
  display: 'flex',
  flexDirection: 'column',
  alignContent: 'center'
}));

function ErrorSentry({ blocked = false }) {
  const theme = useTheme();
  const mobileDevice = useMediaQuery(theme.breakpoints.down('md'));

  useEffect(() => {
    if (localStorage.getItem('ty_datepicker_compare_from')) {
      localStorage.removeItem('ty_datepicker_compare_from');
    }

    if (localStorage.getItem('ty_datepicker_compare_to')) {
      localStorage.removeItem('ty_datepicker_compare_to');
    }

    if (localStorage.getItem('ty_datepicker_from')) {
      localStorage.removeItem('ty_datepicker_from');
    }

    if (localStorage.getItem('ty_datepicker_to')) {
      localStorage.removeItem('ty_datepicker_to');
    }
  }, []);
  if (blocked) {
    return (
      <StyledPage title="Error 401">
        <Typography align="center" variant={mobileDevice ? 'h4' : 'h1'}>
          {t('account.blocked.title')}
        </Typography>
        <Typography align="center" variant="subtitle1">
          {t('account.blocked.description')}
        </Typography>
      </StyledPage>
    );
  }
  return (
    <StyledPage title="Error 404">
      <Typography align="center" variant={mobileDevice ? 'h4' : 'h1'}>
        An error occured while using Thank-You
      </Typography>
      <Typography align="center" variant="subtitle2">
        sorry for the inconvenience
      </Typography>

      <Box mt={6}>
        <Grid container spacing={2} justifyContent="center">
          <Grid item>
            <Button
              color="primary"
              variant="outlined"
              onClick={() => window.location.assign('/')}
            >
              Back to home
            </Button>
          </Grid>
        </Grid>
      </Box>
    </StyledPage>
  );
}

ErrorSentry.propTypes = {
  blocked: PropTypes.bool
};

export default ErrorSentry;
