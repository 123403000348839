import { Select, FormControl, MenuItem, Stack, Grid } from '@mui/material';
import React, { useContext, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import UserContext from '../../../context';
import { useLocalApolloData } from '../../../hooks/localApolloClient';
import { useCustomMutation } from '../../../hooks/graphql';
import { gql, useApolloClient } from '@apollo/client';
import { MdOutlineSubdirectoryArrowRight } from 'react-icons/md';
import { UpdateViewModal } from '../../../pages/client/marketing-analytics/settings/views/UpdateView';
import { truncateWithEllipses } from '../../../helpers/string';

const ViewSelect = () => {
  const { t } = useTranslation();

  const { viewId } = useParams();
  let navigate = useNavigate();
  const {
    selectedView,
    clientSettings,
    username,
    myselfAppConfig
  } = useContext(UserContext);
  const client = useApolloClient();

  const { localGlobalLoading } = useLocalApolloData();

  const UPDATE_SELECTED_ATTRIBUTION_VIEW = gql`
    mutation UpdateSelectedAttributionView(
      $clientId: String!
      $user: String!
      $selectedView: String!
    ) {
      updateSelectedAttributionView(
        clientId: $clientId
        user: $user
        selectedView: $selectedView
      )
    }
  `;

  const [
    updateSelectedAttributionView,
    { loading: mutationLoading }
  ] = useCustomMutation(UPDATE_SELECTED_ATTRIBUTION_VIEW, {
    onCompleted: data => {
      navigate(
        `/marketing-analytics/view/${
          data.updateSelectedAttributionView
        }/${window.location.pathname
          .split('/')
          .slice(4)
          .join('/')}`
      );

      client.refetchQueries({
        include: 'active'
      });
    }
  });

  // Update on first page load and on client change
  useEffect(() => {
    // If view ID in url different of selected view, update selected view
    // after making sure the user has access to view in URL
    if (
      myselfAppConfig.clientId === clientSettings.id &&
      viewId !== selectedView &&
      viewId !== undefined
    ) {
      if (myselfAppConfig?.availableViews?.find(({ id }) => id === viewId)) {
        updateSelectedAttributionView({
          variables: {
            user: username,
            selectedView: viewId
          }
        });
      } else {
        navigate(
          `/marketing-analytics/view/${selectedView}/${window.location.pathname
            .split('/')
            .slice(4)
            .join('/')}`
        );
      }
    }

    // If selected view is not available for user, change selected view for the first available
    if (
      myselfAppConfig.clientId === clientSettings.id &&
      !myselfAppConfig?.availableViews?.some(({ id }) => id === selectedView) &&
      viewId === selectedView
    ) {
      updateSelectedAttributionView({
        variables: {
          user: username,
          selectedView: myselfAppConfig?.availableViews?.[0]?.id
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientSettings.id, myselfAppConfig.clientId]);

  if (
    !window.location.pathname.includes('marketing-analytics') ||
    myselfAppConfig?.availableViews?.length === 0
  ) {
    return null;
  }

  return (
    <Stack direction="row" spacing={0.5} alignItems="center">
      <MdOutlineSubdirectoryArrowRight
        style={{
          transform: 'translateY(2px)'
        }}
      />

      <Grid
        container
        direction="row"
        spacing={0}
        alignItems="center"
        sx={{ transform: 'translateY(2px)' }}
      >
        <Grid item>
          <UpdateViewModal viewId={selectedView} />
        </Grid>
        <Grid item xs>
          <FormControl fullWidth variant="standard">
            <Select
              renderValue={value =>
                truncateWithEllipses(
                  myselfAppConfig?.availableViews?.find(
                    ({ id }) => id === value
                  )?.name
                    ? myselfAppConfig?.availableViews?.find(
                        ({ id }) => id === value
                      ).name
                    : t('commun.dataLoading'),
                  20
                )
              }
              sx={{ fontSize: '0.8rem' }}
              labelId="thankyou-active-view-label"
              id="thankyou-active-view"
              value={selectedView}
              disabled={localGlobalLoading.length > 0 || mutationLoading}
              placeholder="Vue"
              onChange={e => {
                updateSelectedAttributionView({
                  variables: {
                    user: username,
                    selectedView: e.target.value
                  }
                });
              }}
            >
              {[
                ...(myselfAppConfig?.availableViews
                  ? myselfAppConfig.availableViews
                  : [])
              ].map(item => (
                <MenuItem key={item.name} value={item.id}>
                  {item.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      </Grid>
    </Stack>
  );
};

export default ViewSelect;
