import React, { useEffect, useMemo } from 'react';
import { BrowserRouter } from 'react-router-dom';
import objectKeyfilter from 'object-key-filter';

import './app.css';
import { useMyself } from './hooks/myself';
import Sentry from './Sentry';
import { Routes, AdminRoutes } from './Routes';
import ScrollReset from './atoms/ScrollReset';
import LoaderAsync from './atoms/LoaderAsync';
import UserContext from './context';
import { useExport } from './hooks/export';
import { useQuery } from '@apollo/client';
import { useCustomQuery } from './hooks/graphql';
import { GET_CLIENT_SETTINGS, GET_LOCAL_DATA } from './graphql/queries';
import { useAnalyticsBackOfficeImport } from './hooks/analyticsBackOfficeImport';

const App = () => {
  const { error, data } = useQuery(GET_LOCAL_DATA);

  if (error) throw new Error(`Error! ${error.message}`);

  const {
    myself,
    reloadMyself,
    isFromThankYouAnalyticsTeam,
    signOut
  } = useMyself();

  useExport(myself?.id);
  useAnalyticsBackOfficeImport(myself?.id);

  const { data: dataClient } = useCustomQuery(GET_CLIENT_SETTINGS, {
    skip: !data || !myself || myself.isAdmin === true,
    variables: {
      clientId: data?.clientId
    },
    errorHandling: {
      signOutOnError: true
    },
    fetchPolicy: 'network-only'
  });

  const currentClient = useMemo(
    () =>
      myself?.clientsAccess?.find(
        ({ clientId }) => clientId === dataClient?.client?.id
      ),
    [myself, dataClient]
  );

  useEffect(() => {
    if (data) {
      localStorage.setItem('ty_client_id', data.clientId);
      localStorage.setItem(
        'ty_selected_platforms',
        JSON.stringify(data.selectedAffiliatePlatformIds)
      );
      localStorage.setItem(
        'ty_datepicker_compare_from',
        data.datePickerCompareFrom
      );
      localStorage.setItem(
        'ty_datepicker_compare_to',
        data.datePickerCompareTo
      );
      localStorage.setItem('ty_datepicker_from', data.datePickerFrom);
      localStorage.setItem('ty_datepicker_to', data.datePickerTo);
      localStorage.setItem('ty_hours_picker_from', data.hoursPickerFrom);
      localStorage.setItem('ty_hours_picker_to', data.hoursPickerTo);
      localStorage.setItem('ty_dv', JSON.stringify(data.dynamicVariables));
      localStorage.setItem(
        'ty_filter_sentPlatform',
        JSON.stringify(data.filterSentToPlatform)
      );
      localStorage.setItem(
        'ty_filter_closingStatuses',
        JSON.stringify(data.filterClosingStatuses)
      );
      localStorage.setItem(
        'ty_filter_partner_affiliates',
        JSON.stringify(data.filterPartnerSelectedAffiliate)
      );
    }
  }, [data]);

  if (!myself) {
    return <LoaderAsync shouldForceShowing={true} />;
  }

  if (myself?.isAdmin === false && myself?.clientsAccess?.length <= 0) {
    return <p>No company set for this user, please contact an admin</p>;
  }

  if (myself?.isAdmin === false && (!dataClient || !currentClient)) {
    return null;
  }

  return (
    <UserContext.Provider
      value={{
        signOutMyself: signOut,
        isAuthUserFromThankYouAnalyticsTeam: isFromThankYouAnalyticsTeam,
        username: myself?.id,
        userClientSettings: currentClient,
        selectedView: currentClient?.selectedView
          ? currentClient.selectedView
          : 0,
        clientsAccess: myself?.clientsAccess,
        accessManualCosts: myself?.currentClient?.accessManualCosts,
        availableConnectors: myself?.currentClient?.availableConnectors,
        reloadMyself,
        myselfAppConfig: {
          clientId: myself?.currentClient?.id,
          availableViews: myself?.currentClient?.availableViews,
          languageCode: myself.languageCode,
          table:
            myself?.table &&
            objectKeyfilter(myself?.table, ['__typename'], true),
          graphOptions:
            myself?.graphOptions &&
            objectKeyfilter(myself?.graphOptions, ['__typename'], true)
        },
        clientSettings:
          dataClient?.client &&
          objectKeyfilter(dataClient.client, ['__typename'], true),
        userGroup: myself.isAdmin ? 'appAdmin' : currentClient?.group,
        blocked: !!myself?.blocked
      }}
    >
      <BrowserRouter>
        <Sentry>
          <ScrollReset />
          {myself.isAdmin ? <AdminRoutes /> : <Routes />}
        </Sentry>
      </BrowserRouter>
    </UserContext.Provider>
  );
};

export default App;
