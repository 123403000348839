import React, { useState, useEffect, useContext, useMemo } from 'react';
import {
  DialogActions,
  Dialog,
  DialogContent,
  Button,
  Autocomplete,
  DialogTitle,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Stack,
  Box,
  IconButton
} from '@mui/material';
import PropTypes from 'prop-types';
import { gql } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { Add, Remove } from '@mui/icons-material';

import { useAnalyticsFilters } from '../../../../hooks/analyticsFilters';
import Loading from '../../../../atoms/Loading';
import { useCustomQuery } from '../../../../hooks/graphql';
import UserContext from '../../../../context';

const ATTRIB_TARGETS_LIST = gql`
  query AttribTargetsModal(
    $from: String!
    $to: String!
    $filters: [AttributionPings_Filters]
    $timezone: String!
    $listSize: Int!
    $listOffset: Int!
    $listSort: String!
    $listOrder: String!
    $search: String
    $conversionMethodology: String!
    $clientId: ID!
    $visitDefinition: AttributionPingsVisitDefinition!
  ) {
    datePickerFrom @client @export(as: "from")
    datePickerTo @client @export(as: "to")
    client(clientId: $clientId) {
      id
      attributionPings(filters: $filters) {
        targetDetails(
          search: $search
          from: $from
          to: $to
          timezone: $timezone
          listSize: $listSize
          listOffset: $listOffset
          listSort: $listSort
          listOrder: $listOrder
          conversionMethodology: $conversionMethodology
          visitDefinition: $visitDefinition
        ) {
          list {
            id
          }
        }
      }
    }
  }
`;

const TargetModal = ({
  handleClose,
  isOpen,
  viewFilters,
  selectedFilters,
  handleOnUpdate
}) => {
  const { t } = useTranslation();
  const { filters } = useAnalyticsFilters();
  const { clientSettings } = useContext(UserContext);
  const [targetFilterType, setTargetFilterType] = useState('');
  const [urlValues, setUrlValues] = useState([]);
  const [containsType, setContainsType] = useState('or');
  const [containsValues, setContainsValues] = useState(['']);
  const [inputValue, setInputValue] = useState('');

  const { data } = useCustomQuery(ATTRIB_TARGETS_LIST, {
    variables: {
      visitDefinition: clientSettings.attributionPingsSettings.visitDefinition,
      clientId: clientSettings.id,
      conversionMethodology:
        clientSettings.attributionPingsSettings.conversionMethodology,
      timezone: clientSettings.timezone,
      listSort: 'visits',
      listOrder: 'desc',
      search: null,
      listSize: 100,
      listOffset: 0,
      filters: []
    },
    skip: !isOpen || targetFilterType !== 'url',
    fetchPolicy: 'no-cache',
    showLoader: false
  });

  useEffect(() => {
    if (isOpen) {
      const mergedFilters = selectedFilters;

      setUrlValues(
        mergedFilters?.find(({ type }) => type === 'target')?.ids || []
      );

      if (mergedFilters?.some(({ type }) => type === 'targetContainsOr')) {
        setContainsValues(
          mergedFilters?.find(({ type }) => type === 'targetContainsOr')?.ids ||
            []
        );
        setContainsType('or');
        setTargetFilterType('contains');
      }

      if (mergedFilters?.some(({ type }) => type === 'targetContainsAnd')) {
        setContainsValues(
          mergedFilters?.find(({ type }) => type === 'targetContainsAnd')
            ?.ids || []
        );
        setContainsType('and');
        setTargetFilterType('contains');
      }

      if (mergedFilters?.some(({ type }) => type === 'targetNotContainsOr')) {
        setContainsValues(
          mergedFilters?.find(({ type }) => type === 'targetNotContainsOr')
            ?.ids || []
        );
        setContainsType('or');
        setTargetFilterType('notContains');
      }

      if (mergedFilters?.some(({ type }) => type === 'targetNotContainsAnd')) {
        setContainsValues(
          mergedFilters?.find(({ type }) => type === 'targetNotContainsAnd')
            ?.ids || []
        );
        setContainsType('and');
        setTargetFilterType('notContains');
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(filters), isOpen, selectedFilters]);

  const optionsList = useMemo(
    () =>
      data?.client?.attributionPings?.targetDetails?.list
        ?.filter(
          ({ id }) =>
            !viewFilters?.some(({ type }) => type === 'target') ||
            viewFilters?.some(
              ({ type, ids }) => type === 'target' && ids.includes(id)
            )
        )
        .map(({ id }) => id),
    [data, viewFilters]
  );

  return (
    <Dialog maxWidth="sm" fullWidth onClose={handleClose} open={isOpen}>
      <DialogTitle>{t('ma.filter.filterByLandingPage')}</DialogTitle>

      <DialogContent>
        {targetFilterType === 'url' && !data ? (
          <Loading />
        ) : (
          <Stack spacing={2} mt={2}>
            <FormControl fullWidth>
              <InputLabel id="ty-select-target-filter-type">
                {t('ma.filter.targets.filterType')}
              </InputLabel>
              <Select
                label={t('ma.filter.targets.filterType')}
                labelId="ty-select-target-filter-type"
                id="ty-select-target-filter-type"
                value={targetFilterType}
                onChange={e => {
                  setUrlValues([]);
                  setContainsValues(['']);
                  setTargetFilterType(e.target.value);
                }}
              >
                <MenuItem value="url">{t('ma.filter.targets.exact')}</MenuItem>
                <MenuItem value="contains">
                  {t('ma.filter.targets.contains')}
                </MenuItem>
                <MenuItem value="notContains">
                  {t('ma.filter.targets.notContains')}
                </MenuItem>
              </Select>
            </FormControl>

            {targetFilterType === 'url' && (
              <Autocomplete
                multiple
                value={urlValues}
                freeSolo
                onPaste={e => {
                  setUrlValues([...urlValues, e.clipboardData.getData('Text')]);
                  // On paste event is triggered when a user paste something, not when the content is pasted
                  // Need to do this hacky behavior to be able to clean the content
                  setTimeout(
                    () =>
                      setInputValue(
                        inputValue.replace(e.clipboardData.getData('Text'), '')
                      ),
                    0
                  );
                }}
                onChange={(_, v) => {
                  v && setUrlValues(v);
                }}
                id="tags-standard"
                options={optionsList}
                getOptionLabel={option => option}
                onInput={input => setInputValue(input.target.value)}
                inputValue={inputValue}
                renderInput={params => (
                  <TextField
                    {...params}
                    variant="standard"
                    label=""
                    placeholder={t('ma.filter.selectLandingPage')}
                  />
                )}
              />
            )}

            {targetFilterType === 'contains' && (
              <>
                {containsValues.map((item, index) => {
                  return (
                    <Stack
                      key={index}
                      direction="row"
                      spacing={2}
                      alignItems="center"
                    >
                      {index > 0 && (
                        <Select
                          variant="standard"
                          value={containsType}
                          onChange={e => {
                            setContainsType(e.target.value);
                          }}
                        >
                          <MenuItem value="and">{t('commun.and')}</MenuItem>
                          <MenuItem value="or">{t('commun.or')}</MenuItem>
                        </Select>
                      )}
                      <TextField
                        fullWidth
                        onChange={e => {
                          setContainsValues(
                            containsValues.map((i, ind) =>
                              ind === index ? e.target.value : i
                            )
                          );
                        }}
                        placeholder={t('ma.filter.targets.term')}
                        value={item}
                        variant="standard"
                      />
                      {index === containsValues.length - 1 &&
                        containsValues.length < 5 && (
                          <Box>
                            <IconButton
                              size="small"
                              onClick={() =>
                                setContainsValues([...containsValues, ''])
                              }
                            >
                              <Add />
                            </IconButton>
                          </Box>
                        )}

                      <Box>
                        <IconButton
                          disabled={containsValues.length === 1}
                          size="small"
                          onClick={() =>
                            setContainsValues(
                              containsValues.filter((_, i) => i !== index)
                            )
                          }
                        >
                          <Remove />
                        </IconButton>
                      </Box>
                    </Stack>
                  );
                })}
              </>
            )}

            {targetFilterType === 'notContains' && (
              <>
                {containsValues.map((item, index) => {
                  return (
                    <Stack
                      key={index}
                      direction="row"
                      spacing={2}
                      alignItems="center"
                    >
                      {index > 0 && (
                        <Select
                          variant="standard"
                          value={containsType}
                          onChange={e => {
                            setContainsType(e.target.value);
                          }}
                        >
                          <MenuItem value="and">{t('commun.and')}</MenuItem>
                          <MenuItem value="or">{t('commun.or')}</MenuItem>
                        </Select>
                      )}
                      <TextField
                        fullWidth
                        onChange={e => {
                          setContainsValues(
                            containsValues.map((i, ind) =>
                              ind === index ? e.target.value : i
                            )
                          );
                        }}
                        placeholder={t('ma.filter.targets.term')}
                        value={item}
                        variant="standard"
                      />
                      {index === containsValues.length - 1 &&
                        containsValues.length < 5 && (
                          <Box>
                            <IconButton
                              size="small"
                              onClick={() =>
                                setContainsValues([...containsValues, ''])
                              }
                            >
                              <Add />
                            </IconButton>
                          </Box>
                        )}

                      <Box>
                        <IconButton
                          disabled={containsValues.length === 1}
                          size="small"
                          onClick={() =>
                            setContainsValues(
                              containsValues.filter((_, i) => i !== index)
                            )
                          }
                        >
                          <Remove />
                        </IconButton>
                      </Box>
                    </Stack>
                  );
                })}
              </>
            )}
          </Stack>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="secondary">
          {t('commun.cancel')}
        </Button>
        <Button
          disabled={urlValues?.length <= 0 && containsValues?.length <= 0}
          onClick={() => {
            handleOnUpdate(
              urlValues,
              containsValues,
              targetFilterType,
              containsType
            );

            handleClose();
          }}
          color="primary"
        >
          {t('commun.confirm')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

TargetModal.propTypes = {
  handleClose: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  handleOnUpdate: PropTypes.func.isRequired,
  viewFilters: PropTypes.array.isRequired,
  selectedFilters: PropTypes.array.isRequired
};

export default TargetModal;
